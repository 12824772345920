import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import * as Yup from 'yup';
import { Col, Row, Button, Spin, theme, Tooltip } from 'antd';
import InputField from 'components/Forms/input';
import Modal from 'components/modal';
import {
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { DatePickerField, SelectField } from 'components/Forms';
import { useZones } from 'services/ZoneService';
import { Marketplace, useMarketplaces } from 'services/MarketplaceService';
import { LastMileRate, TrunkRate, useRate } from 'services/RateService';
import { selectGlobals } from 'redux/globals/selectors';
import { useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

interface RateModalProps {
  title: string;
  onSubmit: (rate: LastMileRate | TrunkRate) => void;
  type: 'lastMile' | 'trunk';
  rateId?: number;
  loading?: boolean;
  close?: boolean;
}

const RateModalButton = ({
  title,
  onSubmit,
  type = 'lastMile',
  rateId,
  loading = false,
  close = false,
}: RateModalProps) => {
  const [open, setOpen] = useState(false);
  const { token } = theme.useToken();
  const { data: rate, isLoading, isFetched } = useRate(type, rateId, open);
  const globals = useSelector(selectGlobals);
  const validationSchema =
    type === 'lastMile'
      ? Yup.object({
          marketplace_id: Yup.number().required('Seleccione un marketplace'),
          valid_since: Yup.string().required('Ingrese una fecha de inicio'),
          rate: Yup.number()
            .required('Ingrese una tarifa')
            .typeError('Ingrese un número valido'),
          cost: Yup.number().nullable().typeError('Ingrese un número valido'),
        })
      : Yup.object({
          marketplace_id: Yup.number().required('Seleccione un marketplace'),
          valid_since: Yup.string().required('Ingrese una fecha de inicio'),
          rate: Yup.number()
            .required('Ingrese una tarifa')
            .typeError('Ingrese un número valido'),
          cdc_origin_id: Yup.number()
            .required('Ingrese un identificador de origen')
            .typeError('Ingrese un número valido'),
          cdc_destination_id: Yup.number()
            .required('Ingrese un identificador de origen')
            .typeError('Ingrese un número valido'),
          cost: Yup.number().nullable().typeError('Ingrese un número valido'),
        });

  const initialValues = useMemo(
    () =>
      type === 'lastMile'
        ? ({
            marketplace_id: undefined,
            zone_id: undefined,
            package_size: undefined,
            delivery_term: undefined,
            cost: undefined,
            rate: undefined,
            valid_since: undefined,
            valid_until: undefined,
          } as LastMileRate)
        : ({
            marketplace_id: undefined,
            cdc_origin_id: undefined,
            cdc_destination_id: undefined,
            package_size: undefined,
            delivery_term: undefined,
            cost: undefined,
            rate: undefined,
            valid_since: undefined,
            valid_until: undefined,
          } as TrunkRate),
    [type],
  );
  const [formValues, setFormValues] = useState<LastMileRate | TrunkRate>(
    initialValues,
  );

  const {
    allZonesOptions,
    groupingZones: { isFetched: isZonesFetched },
  } = useZones();
  const { data: marketplaces, isFetched: isMkpFetched } = useMarketplaces();

  useEffect(() => {
    if (isFetched && rate) {
      setFormValues(rate);
    }
  }, [rate]);

  useEffect(() => {
    if (close) setOpen(false);
  }, [close]);

  const marketplaceOptions = useMemo(() => {
    return marketplaces?.content
      ? marketplaces.content.map((mkp: Marketplace) => ({
          label: mkp.name,
          value: mkp.id,
        }))
      : [];
  }, [marketplaces]);

  const deliveryTermOptions = useMemo(() => {
    return globals.deliveryTerm
      .filter(
        (term: any) =>
          term.value === 0 ||
          term.value === 1 ||
          term.value === 2 ||
          term.value === 3,
      )
      .map((term: any) => ({
        label: term.name,
        value: term.value,
      }));
  }, [globals.deliveryTerm]);

  const packageSizeOptions = useMemo(() => {
    return globals.packages.size
      .filter((size: any) => size.value !== 4)
      .map((size: any) => ({
        label: size.name,
        value: size.value,
      }));
  }, [globals.packages.size]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          submitForm,
          isSubmitting,
          resetForm,
          dirty,
          values,
          setFieldValue,
          errors,
        }) => (
          <Form>
            <Modal
              title={title}
              open={open}
              afterOpenChange={() => resetForm()}
              onOk={submitForm}
              onClose={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              okText="Confirmar"
              cancelText="Cancelar"
              okButtonProps={{
                loading:
                  ((!isMkpFetched || !isZonesFetched) && !rateId) ||
                  isLoading ||
                  isSubmitting,
                disabled:
                  ((!isMkpFetched || !isZonesFetched) && !rateId) ||
                  isLoading ||
                  isSubmitting ||
                  !dirty ||
                  Object.keys(errors).length > 0,
              }}
            >
              {isLoading && rateId ? (
                <Spin spinning style={{ width: '100%', height: 'auto' }} />
              ) : (
                <Row justify="center" gutter={[8, 16]}>
                  <Col xs={24} md={type === 'lastMile' ? 12 : 24}>
                    <SelectField
                      loading={!isMkpFetched}
                      allowClear
                      label="Marketplace"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      fieldName="marketplace_id"
                      options={marketplaceOptions}
                    />
                  </Col>
                  {type === 'lastMile' && (
                    <Col xs={24} md={12}>
                      <SelectField
                        loading={!isZonesFetched}
                        label="Zona o Agrupadora"
                        fieldName="zone_id"
                        options={allZonesOptions}
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />
                    </Col>
                  )}
                  {type === 'trunk' && (
                    <>
                      <Col xs={24} md={12}>
                        <InputField
                          label="Id de origen de Inter-urbano"
                          fieldName="cdc_origin_id"
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <InputField
                          label="Id de destino de Inter-urbano"
                          fieldName="cdc_destination_id"
                        />
                      </Col>
                    </>
                  )}
                  <Col xs={24} md={12}>
                    <SelectField
                      label="Tamaño"
                      fieldName="package_size"
                      options={packageSizeOptions}
                      allowClear
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <SelectField
                      label="Plazo"
                      fieldName="delivery_term"
                      options={deliveryTermOptions}
                      allowClear
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <DatePickerField
                      label="Válido desde"
                      fieldName="valid_since"
                      disabledDate={(current: Dayjs) =>
                        values.valid_until &&
                        current >= dayjs(values.valid_until).endOf('day')
                      }
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <DatePickerField
                      label="Válido hasta"
                      fieldName="valid_until"
                      disabledDate={(current: Dayjs) =>
                        values.valid_since &&
                        current < dayjs(values.valid_since).startOf('day')
                      }
                      info="Si no se ingresa la fecha de fin, la tarifa aplica como tarifa general a partir de la fecha de inicio, indefinidamente."
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <InputField
                      label="Tarifa"
                      fieldName="rate"
                      onChange={(e) =>
                        setFieldValue('rate', e.target.value.replace(',', '.'))
                      }
                      suffix={
                        <Tooltip title="Refiere al monto a cobrar por el envío.">
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <InputField
                      label="Costo"
                      fieldName="cost"
                      onChange={(e) =>
                        setFieldValue('cost', e.target.value.replace(',', '.'))
                      }
                      suffix={
                        <Tooltip title="Refiere al monto a pagar a los drivers.">
                          <InfoCircleOutlined
                            style={{ color: 'rgba(0,0,0,.45)' }}
                          />
                        </Tooltip>
                      }
                    />
                  </Col>
                </Row>
              )}
            </Modal>
          </Form>
        )}
      </Formik>
      {rateId ? (
        <EditOutlined
          onClick={() => {
            if (!loading) setOpen(true);
          }}
          style={
            loading
              ? { fontSize: 18, color: 'grey', cursor: 'not-allowed' }
              : { fontSize: 18, color: token.colorPrimary }
          }
        />
      ) : (
        <Button
          type="primary"
          onClick={() => setOpen(true)}
          disabled={loading}
          loading={loading}
        >
          <PlusOutlined /> Nueva tarifa
        </Button>
      )}
    </>
  );
};

export default RateModalButton;
