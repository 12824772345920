import { Input, Tooltip, Form, Button, theme, Space } from 'antd';
import React, { ReactElement, useCallback } from 'react';
import { useFormikContext, getIn } from 'formik';
import { UndoOutlined } from '@ant-design/icons';
import useStyles from './styles';

interface Props {
  label?: string;
  labelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  fieldName: string;
  type?: string;
  min?: number;
  max?: number;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (value: string) => void;
  prefix?: string;
  suffix?: ReactElement | string;
  disabled?: boolean;
  placeholder?: string;
  actionButton?: React.ReactElement;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const InputField = ({
  label,
  labelStyle,
  containerStyle,
  fieldName,
  type,
  min,
  max,
  onBlur,
  prefix,
  suffix,
  disabled = false,
  placeholder,
  onChange,
  actionButton,
}: Props) => {
  const { token } = theme.useToken();
  const classes = useStyles({ theme: token });
  const { values, initialValues, errors, setFieldValue, setFieldTouched } =
    useFormikContext<any>();
  const value = getIn(values, fieldName);
  const [input, setInput] = React.useState(value);
  const error = getIn(errors, fieldName);
  const initialValue = getIn(initialValues, fieldName);
  const hasChanged = value && value?.toString() !== initialValue?.toString();

  React.useEffect(() => {
    setInput(value);
  }, [value]);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFieldTouched(fieldName, true);
      setFieldValue(fieldName, e.target.value);
      if (onBlur) {
        onBlur(e.target.value);
      }
    },
    [fieldName, onBlur, setFieldTouched, setFieldValue],
  );
  const handleRestart = useCallback(() => {
    setFieldValue(fieldName, initialValue);
    setFieldTouched(fieldName, false);
  }, [fieldName, initialValue, setFieldValue, setFieldTouched]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange, setInput],
  );

  const getValidateStatus = () => {
    let validateStatus:
      | ''
      | 'error'
      | 'validating'
      | 'warning'
      | 'success'
      | undefined = '';
    if (hasChanged) {
      validateStatus = 'warning';
    }
    if (error) {
      validateStatus = 'error';
    }
    return validateStatus;
  };

  const validateStatus = getValidateStatus();

  const AddonBefore = React.memo(() => {
    return (
      <Tooltip title="Deshacer">
        <Button onClick={() => handleRestart()} size="small" type="text">
          <UndoOutlined />
        </Button>
      </Tooltip>
    );
  });

  return (
    <div style={{ textAlign: 'left', ...containerStyle }}>
      {!!label && <span style={labelStyle}>{label}</span>}
      <div>
        <Form.Item validateStatus={validateStatus} help={error}>
          <Space.Compact>
            <Input
              className={classes.topSelectEffect}
              value={input}
              defaultValue={value}
              onChange={handleChange}
              type={type}
              name={fieldName}
              prefix={prefix}
              suffix={suffix}
              onBlur={handleBlur}
              min={min}
              max={max}
              style={{
                width: '100%',
              }}
              placeholder={placeholder || label}
              disabled={disabled}
              addonAfter={hasChanged ? <AddonBefore /> : null}
            />
            <div
              style={{
                marginTop: 5,
              }}
            >
              {actionButton}
            </div>
          </Space.Compact>
        </Form.Item>
      </div>
    </div>
  );
};

InputField.defaultProps = {
  labelStyle: {},
  containerStyle: {},
  type: 'text',
  min: null,
  max: null,
  onBlur: null,
  prefix: null,
  suffix: null,
  disabled: false,
  placeholder: null,
  onChange: undefined,
};

export default React.memo(InputField);
