/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable eqeqeq */
/* eslint-disable no-return-await */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import Api from 'util/Api';
import api from 'constants/api';
import { useSelector } from 'react-redux';
import { authProfile } from 'redux/auth/selectors';
import { notification } from 'antd';
import dayjs from 'dayjs';

interface Option {
  value: number;
  name: string;
}

// TODO Estos enums no conectan bien. Consultar

const PackageSizeOptions: Option[] = [
  { value: 0, name: 'undefined' },
  { value: 1, name: 'small' },
  { value: 2, name: 'medium' },
  { value: 3, name: 'large' },
];

const DeliveryTermOptions: Option[] = [
  { value: 0, name: 'undefined' },
  { value: 1, name: 'priority' },
  { value: 2, name: 'express' },
  { value: 3, name: 'standard' },
];

interface GetRatesQueries {
  marketplace_id?: number | null;
  package_size?: number | null;
  delivery_term?: number | null;
  page?: number | null;
  size?: number | null;
}

interface GetLastMileQueries extends GetRatesQueries {
  zone_id?: number | null;
}

interface GetTrunkQueries extends GetRatesQueries {
  cdc_origin_id?: number | null;
  cdc_destination_id?: number | null;
}

interface GetRatesResponse {
  data: {
    total_elements: number;
    page_size: number;
    page_number: number;
    items: Rate[];
  };
  message?: string;
}

export interface Rate {
  id?: number;
  rate?: number;
  currency?: string;
  cost?: number;
  marketplace_name?: string;
  marketplace_id?: number;
  package_size?: string | number;
  delivery_term?: string | number;
  valid_since?: Date;
  valid_until?: Date;
  updated?: boolean;
}

export interface LastMileRate extends Rate {
  zone_name?: string;
  zone_id?: number;
}

export interface TrunkRate extends Rate {
  cdc_origin_id?: string;
  cdc_destination_id?: string;
}

interface CreateLastMileRequest extends LastMileRate {
  logistics_operator_id: number;
  tenant: number;
  country_id: number;
}

interface CreateTrunkRequest extends TrunkRate {
  logistics_operator_id: number;
  tenant: number;
  country_id: number;
}

const transformRateFromApi = (rate: Rate) => {
  const packageSize = PackageSizeOptions.find(
    (size: Option) => size.name === rate.package_size,
  );
  const deliveryTerm = DeliveryTermOptions.find(
    (term: Option) => term.name === rate.delivery_term,
  );
  return {
    ...rate,
    package_size: packageSize ? packageSize.value : undefined,
    delivery_term: deliveryTerm ? deliveryTerm.value : undefined,
  };
};

const transformRateToApi = (rate: Rate) => {
  const packageSize = PackageSizeOptions.find(
    (size: Option) => size.value === rate.package_size,
  );
  const deliveryTerm = DeliveryTermOptions.find(
    (term: Option) => term.value === rate.delivery_term,
  );

  return {
    ...rate,
    package_size: packageSize ? packageSize.name : PackageSizeOptions[0].name,
    delivery_term: deliveryTerm
      ? deliveryTerm.name
      : DeliveryTermOptions[0].name,
  };
};

const getLastMileRates = async (
  logisticOperatorId: string,
  queries?: GetLastMileQueries,
): Promise<GetRatesResponse> => {
  const response = await Api.apiAxios.get<GetRatesResponse>(
    api.rates.lastMile.allByOpl(logisticOperatorId),
    {
      params: queries,
    },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error(response.data.message);
  }

  return {
    data: {
      ...response.data.data,
      items: response.data.data.items.map(transformRateFromApi),
    },
    message: response.data.message,
  };
};

const getTrunkRates = async (
  logisticOperatorId: string,
  queries?: GetTrunkQueries,
): Promise<GetRatesResponse> => {
  const response = await Api.apiAxios.get<GetRatesResponse>(
    api.rates.trunk.allByOpl(logisticOperatorId),
    {
      params: queries,
    },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error(response.data.message);
  }

  return {
    data: {
      ...response.data.data,
      items: response.data.data.items.map(transformRateFromApi),
    },
    message: response.data.message,
  };
};

const getLastMileRate = async (rateId: number) => {
  const response = await Api.apiAxios.get<LastMileRate>(
    `${api.rates.lastMile.byId(rateId)}`,
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se ha podido obtener la tarifa');
  }

  return transformRateFromApi(response.data);
};

const getTrunkRate = async (rateId: number) => {
  const response = await Api.apiAxios.get<TrunkRate>(
    `${api.rates.trunk.byId(rateId)}`,
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se ha podido obtener la tarifa');
  }

  return transformRateFromApi(response.data);
};

const createLastMileRate = async (body: CreateLastMileRequest) => {
  const response = await Api.apiAxios.post<{ message: string }>(
    api.rates.lastMile.root,
    transformRateToApi(body),
    { headers: { tenant: body.tenant } },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se ha podido crear la tarifa');
  }

  return true;
};

const createTrunkRate = async (body: CreateTrunkRequest) => {
  const response = await Api.apiAxios.post<{ message: string }>(
    api.rates.trunk.root,
    transformRateToApi(body),
    { headers: { tenant: body.tenant } },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se ha podido crear la tarifa');
  }

  return true;
};

const modifyLastMileRate = async (body: CreateLastMileRequest) => {
  const response = await Api.apiAxios.put<{ message: string }>(
    api.rates.lastMile.root,
    transformRateToApi(body),
    { headers: { tenant: body.tenant } },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se ha podido modificar la tarifa');
  }

  return true;
};

const modifyTrunkRate = async (body: CreateTrunkRequest) => {
  const response = await Api.apiAxios.put<{ message: string }>(
    api.rates.trunk.root,
    transformRateToApi(body),
    { headers: { tenant: body.tenant } },
  );

  if (!Api.isSuccessResponse(response)) {
    throw new Error('No se ha podido modificar la tarifa');
  }

  return true;
};

// HOOKS

export const useRate = (
  type: 'lastMile' | 'trunk',
  rateId?: number,
  enabled = false,
) => {
  return useQuery<LastMileRate | TrunkRate, Error>({
    queryKey: ['lastMileRate', rateId],
    queryFn: () => {
      if (type == 'lastMile') return getLastMileRate(rateId!);
      return getTrunkRate(rateId!);
    },
    retry: 1,
    enabled: !!rateId && enabled,
  });
};

export const useCreateLastMileRate = (queries: GetLastMileQueries) => {
  const queryClient = useQueryClient();
  const profile = useSelector(authProfile);

  const query: any = useMemo(() => {
    const requestQuery = {
      marketplace_id: queries?.marketplace_id
        ? Number(queries.marketplace_id)
        : undefined,
      zone_id: queries?.zone_id ? Number(queries.zone_id) : undefined,
      package_size: queries?.package_size ? queries.package_size : undefined,
      delivery_term: queries?.delivery_term ? queries.delivery_term : undefined,
      page: queries?.page ? Number(queries.page) : 0,
      size: queries?.size ? Number(queries.size) : 10,
    } satisfies GetLastMileQueries;
    return { ...requestQuery, ownerId: profile.logisticOperatorID as number };
  }, [queries, profile]);

  return useMutation({
    mutationFn: async (rate: LastMileRate) =>
      await createLastMileRate({
        ...rate,
        valid_since: dayjs(rate.valid_since).startOf('day').toDate(),
        valid_until: rate.valid_until
          ? dayjs(rate.valid_until).endOf('day').toDate()
          : undefined,
        logistics_operator_id: profile?.logisticOperatorID,
        tenant: profile?.tenantId,
        country_id: profile?.countryID,
      }),
    onMutate: async (newRate) => {
      await queryClient.cancelQueries({
        queryKey: ['lastMile'],
      });
      const previousRatesData = queryClient.getQueryData(['lastMile']);
      queryClient.setQueryData(
        ['lastMile', query],
        (oldRatesData: GetRatesResponse) => ({
          ...oldRatesData,
          data: {
            ...oldRatesData.data,
            items:
              oldRatesData.data &&
              oldRatesData.data.items &&
              oldRatesData.data.items.length
                ? [...oldRatesData.data.items, { ...newRate, updated: true }]
                : [{ ...newRate, updated: true }],
          },
        }),
      );
      return {
        previousRatesData,
      };
    },
    onError: (e, _rate, context) => {
      queryClient.setQueryData(['lastMile', query], context?.previousRatesData);
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al crear la tarifa',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Creación de tarifa',
        description:
          'Se confirmó la creación de la tarifa. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['lastMile'],
      });
    },
  });
};

export const useCreateTrunkRate = (queries: GetTrunkQueries) => {
  const queryClient = useQueryClient();
  const profile = useSelector(authProfile);

  const query: any = useMemo(() => {
    const requestQuery = {
      marketplace_id: queries?.marketplace_id
        ? Number(queries.marketplace_id)
        : undefined,
      cdc_origin_id: queries?.cdc_origin_id
        ? Number(queries.cdc_origin_id)
        : undefined,
      cdc_destination_id: queries?.cdc_destination_id
        ? Number(queries.cdc_destination_id)
        : undefined,
      package_size: queries?.package_size ? queries.package_size : undefined,
      delivery_term: queries?.delivery_term ? queries.delivery_term : undefined,
      page: queries?.page ? Number(queries.page) : 0,
      size: queries?.size ? Number(queries.size) : 10,
    } satisfies GetTrunkQueries;
    return { ...requestQuery, ownerId: profile.logisticOperatorID as number };
  }, [queries, profile]);

  return useMutation({
    mutationFn: async (rate: TrunkRate) =>
      await createTrunkRate({
        ...rate,
        valid_since: dayjs(rate.valid_since).startOf('day').toDate(),
        valid_until: rate.valid_until
          ? dayjs(rate.valid_until).endOf('day').toDate()
          : undefined,
        logistics_operator_id: profile?.logisticOperatorID,
        tenant: profile?.tenantId,
        country_id: profile?.countryID,
      }),
    onMutate: async (newRate) => {
      await queryClient.cancelQueries({
        queryKey: ['trunk', query],
      });
      const previousRatesData = queryClient.getQueryData(['trunk', query]);
      queryClient.setQueryData(
        ['trunk', query],
        (oldRatesData: GetRatesResponse) => {
          return {
            ...oldRatesData,
            data: {
              ...oldRatesData.data,
              items:
                oldRatesData.data &&
                oldRatesData.data.items &&
                oldRatesData.data.items.length
                  ? [...oldRatesData.data.items, { ...newRate, updated: true }]
                  : [{ ...newRate, updated: true }],
            },
          };
        },
      );
      return {
        previousRatesData,
      };
    },
    onError: (e, _rate, context) => {
      queryClient.setQueryData(['trunk', query], context?.previousRatesData);
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al crear la tarifa',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Creación de tarifa',
        description:
          'Se confirmó la creación de la tarifa. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['trunk', query],
      });
    },
  });
};

export const useModifyLastMileRate = (queries: GetLastMileQueries) => {
  const queryClient = useQueryClient();
  const profile = useSelector(authProfile);

  const query: any = useMemo(() => {
    const requestQuery = {
      marketplace_id: queries?.marketplace_id
        ? Number(queries.marketplace_id)
        : undefined,
      zone_id: queries?.zone_id ? Number(queries.zone_id) : undefined,
      package_size: queries?.package_size ? queries.package_size : undefined,
      delivery_term: queries?.delivery_term ? queries.delivery_term : undefined,
      page: queries?.page ? Number(queries.page) : 0,
      size: queries?.size ? Number(queries.size) : 10,
    } satisfies GetLastMileQueries;
    return { ...requestQuery, ownerId: profile.logisticOperatorID as number };
  }, [queries, profile]);

  return useMutation({
    mutationFn: async (rate: LastMileRate) =>
      await modifyLastMileRate({
        ...rate,
        valid_since: dayjs(rate.valid_since).startOf('day').toDate(),
        valid_until: rate.valid_until
          ? dayjs(rate.valid_until).endOf('day').toDate()
          : undefined,
        logistics_operator_id: profile?.logisticOperatorID,
        tenant: profile?.tenantId,
        country_id: profile?.countryID,
      }),
    onMutate: async (newRate) => {
      await queryClient.cancelQueries({
        queryKey: ['lastMile'],
      });
      const previousRatesData = queryClient.getQueryData(['lastMile']);
      queryClient.setQueryData(
        ['lastMile', query],
        (oldRatesData: GetRatesResponse) => {
          const editedItems =
            oldRatesData.data &&
            oldRatesData.data.items &&
            oldRatesData.data.items.length
              ? oldRatesData.data.items.map((rate: LastMileRate) => {
                  if (rate.id != newRate.id) return rate;
                  return {
                    ...newRate,
                    updated: true,
                  };
                })
              : [{ ...newRate, updated: true }];
          return {
            ...oldRatesData,
            data: {
              ...oldRatesData.data,
              items: editedItems,
            },
          };
        },
      );
      return {
        previousRatesData,
      };
    },
    onError: (e, _rate, context) => {
      queryClient.setQueryData(['lastMile', query], context?.previousRatesData);
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al modificar la tarifa',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Edición de tarifa',
        description:
          'Se confirmó la edición de la tarifa. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['lastMile'],
      });
    },
  });
};

export const useModifyTrunkRate = (queries: GetTrunkQueries) => {
  const queryClient = useQueryClient();
  const profile = useSelector(authProfile);

  const query: any = useMemo(() => {
    const requestQuery = {
      marketplace_id: queries?.marketplace_id
        ? Number(queries.marketplace_id)
        : undefined,
      cdc_origin_id: queries?.cdc_origin_id
        ? Number(queries.cdc_origin_id)
        : undefined,
      cdc_destination_id: queries?.cdc_destination_id
        ? Number(queries.cdc_destination_id)
        : undefined,
      package_size: queries?.package_size ? queries.package_size : undefined,
      delivery_term: queries?.delivery_term ? queries.delivery_term : undefined,
      page: queries?.page ? Number(queries.page) : 0,
      size: queries?.size ? Number(queries.size) : 10,
    } satisfies GetTrunkQueries;
    return { ...requestQuery, ownerId: profile.logisticOperatorID as number };
  }, [queries, profile]);

  return useMutation({
    mutationFn: async (rate: TrunkRate) =>
      await modifyTrunkRate({
        ...rate,
        valid_since: dayjs(rate.valid_since).startOf('day').toDate(),
        valid_until: rate.valid_until
          ? dayjs(rate.valid_until).endOf('day').toDate()
          : undefined,
        logistics_operator_id: profile?.logisticOperatorID,
        tenant: profile?.tenantId,
        country_id: profile?.countryID,
      }),
    onMutate: async (newRate) => {
      await queryClient.cancelQueries({
        queryKey: ['trunk'],
      });
      const previousRatesData = queryClient.getQueryData(['trunk']);
      queryClient.setQueryData(
        ['trunk', query],
        (oldRatesData: GetRatesResponse) => {
          const editedItems =
            oldRatesData.data &&
            oldRatesData.data.items &&
            oldRatesData.data.items.length
              ? oldRatesData.data.items.map((rate: TrunkRate) => {
                  if (rate.id != newRate.id) return rate;
                  return {
                    ...newRate,
                    updated: true,
                  };
                })
              : [{ ...newRate, updated: true }];
          return {
            ...oldRatesData,
            data: {
              ...oldRatesData.data,
              items: editedItems,
            },
          };
        },
      );
      return {
        previousRatesData,
      };
    },
    onError: (e, _rate, context) => {
      queryClient.setQueryData(['trunk', query], context?.previousRatesData);
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al modificar la tarifa',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Edición de tarifa',
        description:
          'Se confirmó la edición de la tarifa. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['trunk'],
      });
    },
  });
};

export const useLastMileRates = (queries?: GetLastMileQueries) => {
  const profile = useSelector(authProfile);

  const query = useMemo(() => {
    return {
      marketplace_id: queries?.marketplace_id
        ? Number(queries.marketplace_id)
        : undefined,
      zone_id: queries?.zone_id ? Number(queries.zone_id) : undefined,
      package_size: queries?.package_size ? queries.package_size : undefined,
      delivery_term: queries?.delivery_term ? queries.delivery_term : undefined,
      page: queries?.page ? Number(queries.page) : 0,
      size: queries?.size ? Number(queries.size) : 10,
    } satisfies GetLastMileQueries;
  }, [queries]);

  return useQuery<GetRatesResponse, Error>({
    queryKey: ['lastMile', { ownerId: profile.logisticOperatorID, ...query }],
    queryFn: () => getLastMileRates(profile.logisticOperatorID, query),
    retry: 1,
    refetchOnMount: false,
    staleTime: 300000,
  });
};

export const useTrunkRates = (queries?: GetTrunkQueries) => {
  const profile = useSelector(authProfile);

  const query = useMemo(() => {
    return {
      marketplace_id: queries?.marketplace_id
        ? Number(queries.marketplace_id)
        : undefined,
      cdc_origin_id: queries?.cdc_origin_id
        ? Number(queries.cdc_origin_id)
        : undefined,
      cdc_destination_id: queries?.cdc_destination_id
        ? Number(queries.cdc_destination_id)
        : undefined,
      package_size: queries?.package_size ? queries.package_size : undefined,
      delivery_term: queries?.delivery_term ? queries.delivery_term : undefined,
      page: queries?.page ? Number(queries.page) : 0,
      size: queries?.size ? Number(queries.size) : 10,
    } satisfies GetTrunkQueries;
  }, [queries]);

  return useQuery<GetRatesResponse, Error>({
    queryKey: ['trunk', { ownerId: profile.logisticOperatorID, ...query }],
    queryFn: () => getTrunkRates(profile.logisticOperatorID, query),
    retry: 1,
    refetchOnMount: false,
    staleTime: 300000,
  });
};

export const useDeleteLastMile = (queries?: GetLastMileQueries) => {
  const queryClient = useQueryClient();
  const profile = useSelector(authProfile);

  const query: any = useMemo(() => {
    const requestQuery = {
      marketplace_id: queries?.marketplace_id
        ? Number(queries.marketplace_id)
        : undefined,
      zone_id: queries?.zone_id ? Number(queries.zone_id) : undefined,
      package_size: queries?.package_size ? queries.package_size : undefined,
      delivery_term: queries?.delivery_term ? queries.delivery_term : undefined,
      page: queries?.page ? Number(queries.page) : 0,
      size: queries?.size ? Number(queries.size) : 10,
    } satisfies GetLastMileQueries;
    return { ...requestQuery, ownerId: profile.logisticOperatorID as number };
  }, [queries, profile]);

  return useMutation({
    mutationFn: async (rate: LastMileRate) =>
      await Api.apiAxios.delete(`${api.rates.lastMile.byId(rate.id)}`),
    onMutate: async (rateToDelete) => {
      await queryClient.cancelQueries({
        queryKey: ['lastMile', query],
      });
      const previousRatesData = queryClient.getQueryData(['lastMile', query]);
      queryClient.setQueryData(
        ['lastMile', query],
        (oldRatesData: GetRatesResponse) => {
          const newRates = oldRatesData.data.items.filter(
            (rate: LastMileRate) => rate.id != rateToDelete.id,
          );
          return {
            ...oldRatesData,
            data: { ...oldRatesData.data, items: newRates },
          };
        },
      );
      return {
        previousRatesData,
      };
    },
    onError: (e, _rate, context) => {
      queryClient.setQueryData(['lastMile', query], context?.previousRatesData);
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al eliminar la tarifa',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Eliminación de tarifa',
        description:
          'Se confirmó la eliminación de la tarifa. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['lastMile', query],
      });
    },
  });
};

export const useDeleteTrunk = (queries?: GetTrunkQueries) => {
  const queryClient = useQueryClient();
  const profile = useSelector(authProfile);

  const query: any = useMemo(() => {
    const requestQuery = {
      marketplace_id: queries?.marketplace_id
        ? Number(queries.marketplace_id)
        : undefined,
      cdc_origin_id: queries?.cdc_origin_id
        ? Number(queries.cdc_origin_id)
        : undefined,
      cdc_destination_id: queries?.cdc_destination_id
        ? Number(queries.cdc_destination_id)
        : undefined,
      package_size: queries?.package_size ? queries.package_size : undefined,
      delivery_term: queries?.delivery_term ? queries.delivery_term : undefined,
      page: queries?.page ? Number(queries.page) : 0,
      size: queries?.size ? Number(queries.size) : 10,
    } satisfies GetTrunkQueries;
    return {
      ...requestQuery,
      ownerId: profile.logisticOperatorID as number,
    };
  }, [queries, profile]);

  return useMutation({
    mutationFn: async (rate: TrunkRate) =>
      await Api.apiAxios.delete(`${api.rates.trunk.byId(rate.id)}`),
    onMutate: async (rateToDelete) => {
      await queryClient.cancelQueries({
        queryKey: ['trunk', query],
      });
      const previousRatesData = queryClient.getQueryData(['trunk', query]);
      queryClient.setQueryData(
        ['trunk', query],
        (oldRatesData: GetRatesResponse) => {
          const newRates = oldRatesData.data.items.filter(
            (rate: TrunkRate) => rate.id != rateToDelete.id,
          );
          return {
            ...oldRatesData,
            data: { ...oldRatesData.data, items: newRates },
          };
        },
      );
      return {
        previousRatesData,
      };
    },
    onError: (e, _rate, context) => {
      queryClient.setQueryData(['trunk', query], context?.previousRatesData);
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al eliminar la tarifa',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Eliminación de tarifa',
        description:
          'Se confirmó la eliminación de la tarifa. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['trunk', query],
      });
    },
  });
};
