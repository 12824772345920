/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import SITELINKS from 'constants/sitelinks';
import PaqueryTable, { TableHeader } from '@paquery-team/lib-table';
import {
  useDeleteGroupingZone,
  useDeleteZone,
  useZones,
  Zone,
} from 'services/ZoneService';
import DeleteRecordButton from 'components/deleteRecordButton';
import EditRecordButton from 'components/editRecordButton';
import {
  ClusterOutlined,
  CompassOutlined,
  ExclamationCircleOutlined,
  GatewayOutlined,
  LoadingOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { Flex, Modal, Segmented, theme, Tooltip } from 'antd';
import GoogleMap from 'routes/app/zone/components/map/GoogleMap';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory, useLocation } from 'react-router';
import useUserCityPosition from 'hooks/useUserCity';

const codeColumn = {
  title: 'Código',
  width: 80,
  render: (record: Zone) => record.id ?? <MinusOutlined />,
};
const fullnameColumn = {
  title: 'Nombre',
  dataIndex: 'name',
  ellipsis: true,
};
const parentColumn = {
  title: 'Agrupadora',
  ellipsis: true,
  render: (record: Zone) => record?.parentZone || <MinusOutlined />,
};

const zoneColumns = [codeColumn, fullnameColumn, parentColumn];
const groupingZoneColumns = [codeColumn, fullnameColumn];

const ZonesList = ({
  isGrouping,
  setCenterId,
}: {
  isGrouping: boolean;
  setCenterId: (args: any) => any;
}) => {
  const { token } = theme.useToken();
  const queryClient = useQueryClient();
  const { mutate: deleteZone } = useDeleteZone();
  const { mutate: deleteGroupingZone } = useDeleteGroupingZone();
  const [search, setSearch] = useState('');
  const {
    zones: {
      data: zonesData,
      isLoading: isZonesLoading,
      isFetching: isZonesFetching,
    },
    groupingZones: {
      data: groupingZonesData,
      isLoading: isGroupingZonesLoading,
      isFetching: isGroupingZonesFetching,
      isPlaceholderData: isGroupingZonesPlaceholderData,
    },
  } = useZones();

  const zones = isGrouping ? groupingZonesData : zonesData;
  const isLoading = isGrouping ? isGroupingZonesLoading : isZonesLoading;
  const isFetching = isGrouping
    ? isGroupingZonesFetching ||
      isGroupingZonesPlaceholderData ||
      isZonesFetching
    : isZonesFetching;

  const actionsColumn = {
    align: 'center',
    width: 120,
    render: (record: Zone) => {
      const hasGeoJson =
        (record.path && !!record.path.length && record.path.length > 0) ||
        (record.childrenZones &&
          !!record.childrenZones.length &&
          record.childrenZones.length > 0);
      return (
        <Flex justify="space-around">
          {record.updated || isLoading ? (
            <LoadingOutlined />
          ) : (
            <>
              <EditRecordButton
                link={
                  isGrouping
                    ? SITELINKS.zones.grouping.edit
                    : SITELINKS.zones.edit.forId
                }
                record={record}
              />
              <Tooltip title="Localizar">
                <CompassOutlined
                  onClick={() => {
                    if (hasGeoJson) {
                      setCenterId(record.id);
                    }
                  }}
                  style={
                    hasGeoJson
                      ? { color: token.colorPrimary, fontSize: 18 }
                      : {
                          color: 'lightgrey',
                          fontSize: 18,
                          cursor: 'not-allowed',
                        }
                  }
                />
              </Tooltip>
              <DeleteRecordButton
                onClick={() =>
                  Modal.confirm({
                    title: `¿Esta seguro que quiere eliminar la zona ${isGrouping ? `agrupadora ${record.name}` : record.name}?`,
                    icon: <ExclamationCircleOutlined />,
                    content: `Esta acción no se puede deshacer.${isGrouping ? ' Las zonas hijas volveran a estar disponibles para agrupar.' : ''}`,
                    onOk: () => {
                      if (isGrouping) deleteGroupingZone(record);
                      else deleteZone(record);
                    },
                    okText: 'Eliminar',
                    cancelText: 'Volver',
                  })
                }
              />
            </>
          )}
        </Flex>
      );
    },
  };

  const columns = isGrouping ? groupingZoneColumns : zoneColumns;

  const columnsLargeDevice = [...columns, actionsColumn];

  const searcher = {
    onSearching: (searchText: string) => setSearch(searchText),
    placeholder: isGrouping ? 'Nombre' : 'Nombre o Agrupadora',
    allowEmptySearch: true,
  };

  let filteredZones;
  if (zones && !!zones.length) {
    filteredZones = zones
      .filter(
        (zone: Zone) =>
          zone.name?.toLowerCase().includes(search.toLowerCase()) ||
          (zone.parentZone &&
            zone.parentZone.toLowerCase().includes(search.toLowerCase())),
      )
      .map((z: Zone) => ({ ...z, key: z.id }));
  }
  return (
    <>
      <PaqueryTable
        loading={isLoading}
        fetching={isFetching}
        header={{
          searcher,
          primaryButton: (
            <TableHeader.AddButton
              url={
                isGrouping ? SITELINKS.zones.grouping.add : SITELINKS.zones.add
              }
            />
          ),
          refresh: () => {
            queryClient.invalidateQueries({ queryKey: ['zones'] });
            queryClient.invalidateQueries({ queryKey: ['groupingZones'] });
          },
        }}
        dataSource={filteredZones}
        dataColumns={columns}
        colsForLargeDevice={columnsLargeDevice}
      />
    </>
  );
};

const ZonesView = () => {
  const [centerId, setCenterId] = useState();
  const history = useHistory();
  const location = useLocation();
  const isGrouping = location.pathname === '/zones/grouping';
  const { lat, lng } = useUserCityPosition();
  const {
    zones: {
      data: zonesData,
      isLoading: isZonesLoading,
      isFetching: isZonesFetching,
    },
    groupingZones: {
      data: groupingZonesData,
      isLoading: isGroupingZonesLoading,
      isFetching: isGroupingZonesFetching,
      isPlaceholderData: isGroupingZonesPlaceholderData,
    },
  } = useZones();

  const zones = isGrouping ? groupingZonesData : zonesData;
  const arePolygonsLoading = isGrouping
    ? isGroupingZonesLoading ||
      isGroupingZonesFetching ||
      isGroupingZonesPlaceholderData
    : isZonesLoading || (isZonesFetching && zones.length < 1);

  const handleSegmentChange = (value: number) => {
    history.push(value === 1 ? '/zones/grouping' : '/zones');
  };

  const GoogleMapInstance = useMemo(() => {
    let center;
    if (centerId) {
      [center] = zones.filter(({ id }: Zone) => id == centerId);
    }
    return (
      <GoogleMap
        arePolygonsLoading={arePolygonsLoading}
        initialCenter={{
          lat: lat ?? -34.601692,
          lng: lng ?? -58.431461,
        }}
        zoom={12}
        polygons={zones}
        centerPolygon={center}
      />
    );
  }, [centerId, zones, arePolygonsLoading, lat, lng]);

  return (
    <Flex
      vertical={false}
      gap={16}
      style={{ width: '100%', minHeight: '100%' }}
    >
      <Flex vertical style={{ width: '40%' }}>
        <Segmented
          value={location.pathname == '/zones/grouping' ? 1 : 0}
          onChange={handleSegmentChange}
          options={[
            { label: 'Zonas', value: 0, icon: <GatewayOutlined /> },
            { label: 'Grupos', value: 1, icon: <ClusterOutlined /> },
          ]}
          block
          style={{
            background: 'lightgrey',
            marginBottom: '16px',
            marginTop: '1px',
          }}
        />
        <ZonesList isGrouping={isGrouping} setCenterId={setCenterId} />
      </Flex>
      <div style={{ width: '60%', minHeight: '100%' }}>{GoogleMapInstance}</div>
    </Flex>
  );
};

export default ZonesView;
