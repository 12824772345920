import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import api from 'constants/api';
import Api from 'util/Api';

export interface LinkedPacket {
  id: number;
  trackingNumber: string;
  shipmentDestinationAddress: string;
  status: number;
}

interface LinkIdentifiers {
  trackCode: string;
  codeStock: string;
}

const fetchAssignedCode = async (
  externalCode: string,
): Promise<string | null> => {
  const response = await Api.apiAxios.get<string>(
    api.interbank.checkByTrackcode(externalCode),
  );

  if (!Api.isSuccessResponse(response)) {
    if (response.status === 400) return null;
    const error = new Error(
      'Ocurrió un error buscando el código de este envío.',
    );
    notification.error(error);
    throw error;
  }

  return response.data;
};

const fetchLinkedPacket = async (
  cardCode: string,
): Promise<LinkedPacket | null> => {
  const response = await Api.apiAxios.get<LinkedPacket>(
    api.interbank.stateByCode(cardCode),
  );
  if (!Api.isSuccessResponse(response)) {
    if (response.status === 400) return null;
    const error = new Error(
      'Ocurrió un error verificando la disponibilidad de este código.',
    );
    notification.error(error);
    throw error;
  }

  return response.data;
};

const linkCard = async (
  trackCode: string,
  codeStock: string,
): Promise<void> => {
  const response = await Api.apiAxios.post(api.interbank.link, {
    trackCode,
    codeStock,
  });

  if (!Api.isSuccessResponse(response))
    throw new Error(
      'No se ha podido asignar el código. Intente de nuevo más tarde.',
    );
};

const unlinkCard = async (
  trackCode: string,
  codeStock: string,
): Promise<void> => {
  const response = await Api.apiAxios.put(api.interbank.link, {
    trackCode,
    codeStock,
  });

  if (!Api.isSuccessResponse(response))
    throw new Error(
      'No se ha podido desasignar el código. Intente de nuevo más tarde.',
    );
};

export const useCheckCode = (trackCode: string) => {
  return useQuery<string | null, Error>({
    queryKey: ['code', trackCode],
    queryFn: () => fetchAssignedCode(trackCode),
    enabled: !!trackCode,
    retry: 1,
  });
};

export const useCheckAvailableCode = (codeStock: string, enabled: boolean) => {
  return useQuery<LinkedPacket | null, Error>({
    queryKey: ['linkedCode', codeStock],
    queryFn: () => fetchLinkedPacket(codeStock),
    enabled: !!codeStock && enabled,
    retry: 1,
  });
};

export const useLink = () => {
  return useMutation({
    mutationFn: ({ trackCode, codeStock }: LinkIdentifiers) =>
      linkCard(trackCode, codeStock),
    onSuccess: () =>
      notification.success({
        message: 'Vinculación exitosa',
        description:
          'Se ha vinculado el código de tarjeta ingresado a este envío.',
      }),
    onError: (error) => {
      return notification.error({
        message: 'Ocurrio un error',
        description: error.message,
      });
    },
  });
};

export const useUnlink = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ trackCode, codeStock }: LinkIdentifiers) =>
      unlinkCard(trackCode, codeStock),
    onSuccess: (_, { trackCode, codeStock }) => {
      queryClient.cancelQueries({ queryKey: ['code', trackCode] });
      queryClient.resetQueries({ queryKey: ['code', trackCode] });
      queryClient.resetQueries({ queryKey: ['linkedCode', codeStock] });
      queryClient.cancelQueries({ queryKey: ['linkedCode', codeStock] });
      notification.success({
        message: 'Desvinculación exitosa',
        description: 'Se ha desvinculado el código de tarjeta de este envío.',
      });
    },
    onError: (error) => {
      return notification.error({
        message: 'Ocurrio un error',
        description: error.message,
      });
    },
  });
};
