import { Button, Col, Flex, Input, Row, Space, Spin, Tag } from 'antd';
import { Card as AntdCard } from 'antd/lib';
import { useEffect, useState } from 'react';
import { DisconnectOutlined, EyeOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import { useHistory } from 'react-router';
import SITELINKS from 'constants/sitelinks';
import { useDebounce } from 'util/Hooks';
import {
  useCheckAvailableCode,
  useCheckCode,
  useLink,
  useUnlink,
} from 'services/InterbankService';

const { Meta } = AntdCard;

interface InterbankProps {
  packet: any;
}

export default function Interbank({ packet }: InterbankProps) {
  const {
    packages: { status: packageStatuses },
  } = useSelector(selectGlobals);
  const history = useHistory();
  const [code, setCode] = useState<string>('');
  const [lastCode, setLastCode] = useState<string>('');
  const debouncedCode = useDebounce(code, 500);
  const {
    data: linkedCodeStock,
    isLoading: loadingLinkedCode,
    isFetched: isLinkedCodeFetched,
  } = useCheckCode(packet.externalCode);
  const {
    data: alreadyLinkedPacket,
    isFetching: isCheckFetching,
    isFetched: isCheckFetched,
  } = useCheckAvailableCode(debouncedCode, !linkedCodeStock);
  const {
    mutate: linkCardCode,
    isPending: isPendingLink,
    isSuccess: isLinkSuccess,
    reset: resetLink,
  } = useLink();
  const {
    mutate: unlinkCardCode,
    isPending: isPendingUnlink,
    isSuccess: isUnlinkSuccess,
    reset: resetUnlink,
  } = useUnlink();

  useEffect(() => {
    if (!debouncedCode && isLinkedCodeFetched && linkedCodeStock)
      setCode(linkedCodeStock);
  }, [isLinkedCodeFetched, linkedCodeStock, debouncedCode]);

  useEffect(() => {
    if (isUnlinkSuccess && debouncedCode) {
      setCode(lastCode ?? '');
      resetLink();
      resetUnlink();
    }
  }, [isUnlinkSuccess, debouncedCode, lastCode]);

  const handleLink = () => {
    linkCardCode({ codeStock: debouncedCode, trackCode: packet.externalCode });
  };

  const handleUnlink = () => {
    setLastCode('');
    unlinkCardCode({
      codeStock: debouncedCode,
      trackCode: packet.externalCode,
    });
  };

  const handleUnlinkFetched = () => {
    if (alreadyLinkedPacket) {
      setLastCode(debouncedCode);
      unlinkCardCode({
        codeStock: debouncedCode,
        trackCode: alreadyLinkedPacket.trackingNumber,
      });
    }
  };

  return (
    <Spin spinning={loadingLinkedCode}>
      <Row style={{ minHeight: '20vh' }}>
        <Col xs={24} sm={12} style={{ minHeight: '100%' }}>
          <Flex
            justify="center"
            align="center"
            style={{ height: '100%' }}
            vertical
          >
            <p>Ingrese un código de tarjeta para vincular</p>
            <Space.Compact style={{ margin: '8px' }}>
              <Input
                value={code}
                placeholder="Código de tarjeta"
                onChange={(e) => setCode(e.target.value)}
              />
              {(isLinkedCodeFetched && linkedCodeStock) || isLinkSuccess ? (
                <Button
                  type="primary"
                  danger
                  onClick={handleUnlink}
                  loading={isPendingUnlink}
                  disabled={isPendingUnlink}
                  icon={<DisconnectOutlined />}
                >
                  Desvincular
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={
                    !debouncedCode ||
                    isCheckFetching ||
                    (isCheckFetched && !!alreadyLinkedPacket) ||
                    isPendingLink
                  }
                  loading={isCheckFetching || isPendingLink}
                  onClick={handleLink}
                >
                  Vincular
                </Button>
              )}
            </Space.Compact>
            {alreadyLinkedPacket && (
              <p style={{ color: 'red' }}>
                El código ingresado ya está vinculado a otro envío.
              </p>
            )}
          </Flex>
        </Col>
        <Col xs={24} sm={12}>
          {isCheckFetched && alreadyLinkedPacket && (
            <AntdCard
              actions={[
                <Button
                  type="text"
                  style={{ margin: 0 }}
                  icon={<EyeOutlined />}
                  onClick={() =>
                    history.push(
                      SITELINKS.packages.editById,
                      alreadyLinkedPacket.id,
                    )
                  }
                >
                  Ver detalle
                </Button>,
                <Button
                  type="text"
                  danger
                  style={{ margin: 0 }}
                  icon={<DisconnectOutlined />}
                  loading={isPendingUnlink}
                  disabled={isPendingUnlink}
                  onClick={handleUnlinkFetched}
                >
                  Desvincular
                </Button>,
              ]}
            >
              <Meta title={alreadyLinkedPacket.trackingNumber} />
              <Row gutter={32} style={{ marginTop: '16px' }}>
                <Col xs={24} sm={12}>
                  <p>Dirección de envío</p>
                  <b>{alreadyLinkedPacket.shipmentDestinationAddress}</b>
                </Col>
                {packageStatuses && (
                  <Col xs={24} sm={12}>
                    <p>Estado</p>
                    <Tag color="green">
                      {
                        packageStatuses.find(
                          (status: any) =>
                            status.value === alreadyLinkedPacket.status,
                        ).name
                      }
                    </Tag>
                  </Col>
                )}
              </Row>
            </AntdCard>
          )}
        </Col>
      </Row>
    </Spin>
  );
}
