import React, { useMemo } from 'react';
import type { ExtraTab } from '@paquery-team/lib-package-detail/dist/types';
import { MARKETPLACE_OWNERTYPE } from 'constants/defaultValues';
import Interbank from 'integrations/interbank';
import { CreditCardOutlined } from '@ant-design/icons';

const interbankId = process.env.REACT_APP_INTERBANK_ID;

const useDetailIntegrations = (packet: any): ExtraTab[] => {
  const extraTabs = useMemo(() => {
    const tabs: ExtraTab[] = [];

    // Interbank
    if (
      packet?.ownerType === MARKETPLACE_OWNERTYPE &&
      packet?.ownerID === interbankId
    ) {
      tabs.push({
        menuTitle: <b style={{ color: 'CornflowerBlue' }}>Código de tarjeta</b>,
        cardTitle: 'Código de tarjeta',
        key: 'interbank',
        fieldListeners: ['cardCode'],
        icon: <CreditCardOutlined style={{ color: 'CornflowerBlue' }} />,
        content: <Interbank packet={packet} />,
      });
    }

    return tabs;
  }, [packet]);

  return extraTabs;
};

export default useDetailIntegrations;
